<template>
  <div v-if="checkPermission(['designation', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.designation_id"
        class="holamundo editModal"
        title="Update Designation"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Designation Name*</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="designation name"
                        v-model="form.designation_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('designation name')"
                        >{{ errors.first("designation name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-left md:text-right">
                    <h5 class="mb-0">Description</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <vs-textarea
                      v-model="form.designation_description"
                      class="mb-0"
                      rows="4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Update</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import designationService from "@/services/designationService.js";
import companyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        designation_name: "",
        designation_description: "",
        company_id: "",
        designation_active: "1",
      },
      companies: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (newVal.designation_name) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
    console.log("this.form, this.params.data :", this.form);
    // this.getCompanyList();
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.designation_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      // console.log(this.params.data.department_active, "fsdfsd");

      // console.log("afsadf");
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let id = this.form.designation_id;

          let payload = {
            designation_name: this.form.designation_name,
            designation_description: this.form.designation_description,
            company_id: this.form.company_id,
            designation_active: this.form.designation_active,
          };
          designationService
            .editDesignation(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTabledesi", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getCompanyList: function() {
      // console.log("RolesService :", RolesService);
      companyService
        .getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {},
});
</script>
<style></style>
