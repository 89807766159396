var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vx-card',{staticClass:"form-title",attrs:{"title":"Add Designation"}},[_c('div',{staticClass:"vx-row col-12 d-flex justify-content-end"},[_c('vs-button',{staticClass:"w-full sm:w-auto mb-5 mr-8 ml-8 sm:mr-0 sm:ml-0"},[_c('span',{staticClass:" pr-5 text-white"},[_vm._v("Import")]),_c('img',{staticClass:"d-inline-flex",attrs:{"src":require("@/assets/images/icons/import-excel.svg"),"height":"20","width":"20"}})]),_c('div',{staticClass:"vx-col w-full sm:w-auto mr-5 ml-5 sm:mr-0 sm:ml-0"},[_c('vx-tooltip',{attrs:{"text":"Template Excel","position":"left"}},[_c('vs-button',{staticClass:" px-2 py-3 w-full sm:w-auto",attrs:{"type":"border"},nativeOn:{"click":function($event){return _vm.onClickSampleDownload(
                    _vm.excelurl +
                      "/v2/designation/excel/sample?organization_id=" + _vm.organization_id
                  )}}},[_c('img',{staticClass:"d-inline-flex mr-2",attrs:{"src":require("@/assets/images/icons/excel.svg"),"height":"20","width":"20"}}),_vm._v("Sample Excel\n              ")])],1),_c('vx-tooltip',{staticClass:"w-full sm:w-auto",attrs:{"text":"Import Excel","position":"left"}},[_c('label',{attrs:{"for":"import-file"}},[_c('input',{ref:"uploadExcelFile",staticClass:"d-none",attrs:{"type":"file","name":"import-file","id":"import-file"},on:{"change":function($event){return _vm.uploadExcel($event)}}})])])],1)],1),_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-3/4 mx-auto"},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full  align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Designation Name*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',{},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|alpha_spaces'),expression:"'required|alpha_spaces'"}],staticClass:"w-full",attrs:{"name":"Designation Name"},model:{value:(_vm.form.designation_name),callback:function ($$v) {_vm.$set(_vm.form, "designation_name", $$v)},expression:"form.designation_name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Designation Name')),expression:"errors.has('Designation Name')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("Designation Name")))])],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full  align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Company*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',[_c('multiselect',{attrs:{"track-by":"company_id","label":"company_name","options":_vm.companies,"name":"Company Name","searchable":true,"allow-empty":false,"open-direction":"bottom","deselect-label":"Can't remove this value"},on:{"input":function (ref) {
                                            var company_id = ref.company_id;

                                            return (this$1.form.company_id = company_id);
}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
                          var option = ref.option;
return [_vm._v("\n                        "+_vm._s(option.company_name)+"\n                      ")]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Company Name')),expression:"errors.has('Company Name')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("Company Name")))])],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full "},[_c('h6',{staticClass:"mb-0"},[_vm._v("Description")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-textarea',{staticClass:"mb-0",attrs:{"rows":"4"},model:{value:(_vm.designation_description),callback:function ($$v) {_vm.designation_description=$$v},expression:"designation_description"}})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Status*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-switch',{model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1)])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col mx-auto"},[_c('vs-button',{staticClass:"mr-3 mb-1 w-full sm:w-auto",attrs:{"disabled":_vm.submitStatus,"type":"filled"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v("Submit")]),_c('vs-button',{staticClass:"mb-1 w-full sm:w-auto",attrs:{"color":"warning","type":"border"},on:{"click":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_vm._v("Reset")])],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }